import { default as confirmation9gwgmIuAXjMeta } from "/usr/src/app/pages/confirmation.vue?macro=true";
import { default as datenschutzBjdGXvboxrMeta } from "/usr/src/app/pages/datenschutz.vue?macro=true";
import { default as formular64rHdWn5qCMeta } from "/usr/src/app/pages/formular.vue?macro=true";
import { default as indexgw2ikJsyhcMeta } from "/usr/src/app/pages/index.vue?macro=true";
import { default as screen2saver66iJF2bFjMeta } from "/usr/src/app/pages/screen2save.vue?macro=true";
export default [
  {
    name: "confirmation",
    path: "/confirmation",
    component: () => import("/usr/src/app/pages/confirmation.vue")
  },
  {
    name: "datenschutz",
    path: "/datenschutz",
    component: () => import("/usr/src/app/pages/datenschutz.vue")
  },
  {
    name: "formular",
    path: "/formular",
    component: () => import("/usr/src/app/pages/formular.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/usr/src/app/pages/index.vue")
  },
  {
    name: "screen2save",
    path: "/screen2save",
    meta: screen2saver66iJF2bFjMeta || {},
    component: () => import("/usr/src/app/pages/screen2save.vue")
  }
]