// plugins/gsap.client.ts
import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
import MotionPathPlugin from 'gsap/MotionPathPlugin'

gsap.registerPlugin(ScrollTrigger)
gsap.registerPlugin(MotionPathPlugin)

export default defineNuxtPlugin((nuxtApp) => {
    nuxtApp.provide('gsap', gsap)
})
